@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Lexend font */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

/* Global Styles */
body {
  font-family: 'Lexend', sans-serif; /* Set the Lexend font globally */
}

/* Gradient Background Class */
.gradient-background {
  background: linear-gradient(90deg, #FB8802 0%, #FFE649 99.99%);
}

/* Button Hover Effect */
.button-hover {
  transition: transform 0.2s;
}

.button-hover:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}


/* Add this to your CSS file */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.8); /* Mostly white with transparency */
  border-top: 8px solid orange; /* Top part is orange */
  border-radius: 50%;
  animation: spin 2s linear infinite; /* Use the spin animation */
}
