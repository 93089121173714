.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Account image styles */
.account-image {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: inherit;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  display: block;
  vertical-align: top; /* Ensure alignment is at the top */
  height: auto; /* Maintain aspect ratio */
  max-width: none; /* Remove max-width to allow specified width */
  width: 593px; /* Set the specific width */
}

/* Additional styles for the confirmation container */
.confirmation-container {
  background: linear-gradient(90deg, #FB8802 0%, #FFE649 99.99%); /* Keep your gradient */
  color: black; /* Text color */
  padding: 0; /* Remove padding to eliminate white edges */
  margin: 0; /* Ensure there's no margin */
  width: 100vw; /* Ensure it takes full width */
  height: 100vh; /* Ensure it takes full height */
  text-align: left; /* Align text to the left */
  position: relative; /* Ensure child elements can be positioned absolutely */
}




